<template>
    <!-- main-header -->
    <LxpMobileHeader title="신입행원 온보딩">
      <template v-slot:left>
        <div class="util util-back">
          <a href="javascript:" class="util-actions util-actions-back" @click="goBack">
            <i class="icon-history-back"></i>
          </a>
        </div>
      </template>
    </LxpMobileHeader>
    <!-- begin::kb-main -->
    <main class="kb-main" id="kb-newcomer">
      <!-- main-content -->
      <div class="main-content min-component">
        <!-- newcomer-header -->
        <div class="newcomer-header">
<!--          <p class="subtitle">상위 14.2%</p>-->
          <p class="subtitle"></p>
          <!-- is-finish로 수료시 표시 -->
          <div class="title-badges">
            <template v-if="newZMemitems.length > 0">
              <div class="badge badge-primary" v-if="newZMemitems[0].fnshYn === 'Y'">
                <span class="badge-text">수료</span>
              </div>
              <div class="badge badge-silver" v-else>
                <span class="badge-text">미수료</span>
              </div>
              <div class="badge badge-primary" v-if="newZMemitems[0].slctnYn === 'Y'">
                <span class="badge-text">선정</span>
              </div>
              <div class="badge badge-silver" v-else>
                <span class="badge-text">미선정</span>
              </div>
            </template>
          </div>
          <p v-if="newzTotInfo?.crseNm">차수명: {{ newzTotInfo?.crseNm }} <br>기간: {{newzTotInfo?.bgngDt}} ~ {{ newzTotInfo?.endDt}}</p>
<!--          <p>신입행원 온보딩 KB39기(L1) ・ 연수기간 2021.6.25.금 - 2021.7.25.화</p>-->
          <p v-if="newzTotInfo" style="font-weight: bold"> (간이평가: 2022.03.07 ~ 2023.09.30 기준)</p>
        </div>
        <!-- newcomer-container -->
        <div class="newcomer-container">
          <!-- content-section:총점 -->
          <article class="content-section">
            <header class="section-header">
              <h3 class="title">총점</h3>
            </header>
            <div class="symbol-list-wrap">
              <!-- symbol-item -->
              <div class="symbol-item symbol-item-primary">
                <div class="card-icon"><i class="icon-final"></i></div>
                <div class="card-content">
                  <div class="content-title">최종점수</div>
                  <template v-if="newzTotInfo">
                    <div class="content-title">{{ (Number(newzTotInfo?.cmmnScore)+Number(newzTotInfo?.selfScore)+Number(newzTotInfo?.jikgunScore)+Number(newzTotInfo?.adtnScore)  )}}점</div>
                  </template>
                  <template v-else>
                    <div class="content-title">-</div>
                  </template>
                </div>
              </div>
              <!-- symbol-item -->
              <div class="symbol-item">
                <div class="card-icon"><i class="icon-activity"></i></div>
                <div class="card-content">
                  <div class="content-title">학습활동 점수</div>
                  <template v-if="newzTotInfo">
                    <div class="content-title">{{ (Number(newzTotInfo?.cmmnScore)+Number(newzTotInfo?.selfScore)+Number(newzTotInfo?.jikgunScore)  )}}점</div>
                  </template>
                  <template v-else>
                    <div class="content-title">-</div>
                  </template>
                </div>
              </div>
              <!-- symbol-item -->
              <div class="symbol-item">
                <div class="card-icon"><i class="icon-add"></i></div>
                <div class="card-content">
                  <div class="content-title">가점</div>
                  <template v-if="newzTotInfo">
                    <div class="content-title">{{ Number(newzTotInfo?.adtnScore) }}점</div>
                  </template>
                  <template v-else>
                    <div class="content-title">-</div>
                  </template>
                </div>
              </div>
              <!-- symbol-item -->
              <div class="symbol-item">
                <div class="card-icon"><i class="icon-unfinished"></i></div>
                <div class="card-content">
                  <div class="content-title">미수료 항목</div>
                  <template v-if="newZMemitems.length > 0">
                    <div class="content-title">{{ 0 }}개</div>
                  </template>
                  <template v-else>
                    <div class="content-title">-</div>
                  </template>
                </div>
              </div>
            </div>
            <div class="section-bottom bottom-divider">
              <p class="text">획득점수 70점 이상 득점자로 상위 10%이내, 무득점 항목이 없는 경우 우수학습자 선정</p>

            </div>
          </article>
          <!-- content-section:공통 -->
          <article class="content-section">
            <header class="section-header ">
              <h4 >※ 항목별 취득점수 클릭 시 상세내용 확인가능</h4>
            </header>
            <header class="section-header ">
              <span class="text"><span style="font-weight: bold">* 공통항목 미수료 시 패널티(해당 직급 기간) :</span> 본부부서 공모 및 HR부(인재개발) 주관 연수 공모 선발 시 제외<br>(단, '영업점 필수 자격증 미취득'으로 인한 미수료의 경우, 연수기간 후 모두 취득 시 페널티 해제)</span>
            </header>

            <header class="section-header header-divider">
              <h3 class="title">공통</h3>
            </header>
            <div class="content-list ">

              <div class="content-row">
                <template v-if="newzTotInfo">     
                  
                  <h5 @click="fnGoItemDtlScore(newzTotInfo?.cmmnClssCd)">{{ newzTotInfo?.cmmnScore}} <span class="sub">/ {{ newzTotInfo?.cmmnMaxScore}}</span></h5>

                </template>
                <template v-else>
                  <h5>- <span class="sub">/ -</span></h5>
                </template>     
              </div>
     
            </div>


          </article>
          <!-- content-section:자기계발 노력도 -->
          <article class="content-section">
            <header class="section-header ">
              <span class="text"> <span style="font-weight: bold">* 회의체 참여 :</span> 연간 최대 5점</span>
            </header>
            <header class="section-header header-divider">
              <h3 class="title">자기계발 노력도</h3>
            </header>
            <div class="contents-list">
              <div class="content-row">
                <template v-if="newzTotInfo">
                  <h5 @click="fnGoItemDtlScore(newzTotInfo?.selfClssCd)">{{ newzTotInfo?.selfScore}} <span class="sub">/ {{ newzTotInfo?.selfMaxScore}}</span></h5>
                </template>
                <template v-else>
                  <h5>- <span class="sub">/ -</span></h5>
                </template>

              </div>
            </div>
          </article>
          <!-- content-section:직군별 선택 -->
          <article class="content-section">
            <header class="section-header ">
              <span class="text "><span style="font-weight: bold">* 2023년(간이평가) :</span> UB 리그테이블 점수 30점 환산 반영</span>
            </header>
            <header class="section-header header-divider">
              <h3 class="title">직군별 선택</h3>
            </header>
            <div class="contents-list">
              <template v-if="newzTotInfo">                
                  <div class="content-row">
                    <h5 @click="fnGoItemDtlScore(newzTotInfo?.jikgunClssCd)">{{ newzTotInfo?.jikgunScore}} <span class="sub">/ {{ newzTotInfo?.jikgunMaxScore}}</span></h5>
                  </div>
                </template>
                <template v-else>
                  <h5>- <span class="sub">/ -</span></h5>
                </template>
            </div>
          </article>
          <!-- content-section:가점 -->
          <article class="content-section">
            <header class="section-header header-divider">
              <h3 class="title">가점</h3>
            </header>
            <div class="contents-list">
              <div class="content-row">
                <template v-if="newzTotInfo">
                  <h5 @click="fnGoItemDtlScore(newzTotInfo?.adtnClssCd)">{{ newzTotInfo?.adtnScore}} <span class="sub">/ {{ newzTotInfo?.adtnMaxScore}}</span></h5>
                </template>
                <template v-else>
                  <h5>- <span class="sub">/ -</span></h5>
                </template>
              </div>
            </div>
          </article>
          <!-- content-section:공지사항 -->
          <article class="content-section section-margin">
            <header class="section-header header-divider">
              <h3 class="title">공지사항</h3>
            </header>
            <div class="notice-list-container">
              <div class="board-list-container board-list-bordered">
                <ul class="board-list">
                  <li v-for="(item, idx) in items" class="board-list-item" :key="idx">
                    <article class="board-row">
                      <router-link :to="{name: 'JuniorNoticeView', params: {comNtcSn: item.comNtcSn}}" class="board-link"></router-link>
<!--                      <div v-if="item.ntcFileAtchYn === 'Y'" class="board-column column-file"><a href="javascript:" class="file-link"><i class="icon-file"></i></a></div>-->
                      <div class="board-column column-title">
                        <span class="title">{{item.ntcTitle}}</span>
                        <i class="icon-new"></i>
                      </div>
                      <div class="board-row board-meta"><span class="text">{{ timestampToDateFormat( item.regDt, 'yyyy-MM-dd')}}</span> <span class="text">{{item.inqCnt}} 조회</span></div>
                    </article>
                  </li>
                </ul>
                <div class="board-bottom">
                  <template v-if="items.length > 0">
                    <div class="header-sub"><router-link :to="{name: 'JuniorNotice', params: {boardType: 'onboarding'}}" class="more-link me-4">전체보기</router-link></div>
                  </template>
                </div>
              </div>
            </div>
            <div v-if="items.length === 0" class="search-result">
              <div class="result-empty">
                <img src="@/assets/lxp/mobile/images/_common/img_empty.png">
                <p class="text">공지사항이 없어요</p>
              </div>
            </div>
          </article>
        </div>
        <!-- //main-content -->
      </div>
    </main>
    <!-- end::kb-main -->
</template>

<script setup>
import LxpMobileHeader from '@/views/layout/lxp/header/mobile/LxpMobileHeader';
import {ACT_GET_HELP_NOTICE_LIST} from "@/store/modules/help/help";
import {computed, onMounted, ref} from 'vue';
import {useStore} from 'vuex';
import { useRouter} from "vue-router";
import {
  getItems,
  lengthCheck,
  timestampToDateFormat
} from "@/assets/js/util";
import {expsrLocCds} from "@/assets/js/modules/help/help-common";
import {ACT_GET_TOT_LRNER_SCORE} from '@/store/modules/newzonboarding/newz';
import {goBack} from '@/assets/js/modules/common/common';

    const store = useStore();

    const session = computed(() => store.state.auth.session);
    const items = ref([]);
    const newZMemitems = ref([]);
    const newzTotInfo = ref();
     const router = useRouter();

    const getOnboardingNoticeList = () => {
      store.dispatch(`help/${ACT_GET_HELP_NOTICE_LIST}`, {
        expsrLocCd: expsrLocCds.EXPSR_LOC_CD_ON_BOARDING,
        pageNo: 1,
        pageSize: 3
      }).then(res => {
        if (lengthCheck(res)) {
          items.value = getItems(res);
        } else {
          items.value = [];
        }
      }).catch(e => {
        console.error(e);
      })
    }

    const getNewZMemList = () => {
      store.dispatch(`newz/${ACT_GET_TOT_LRNER_SCORE}`, {
        lrnerId: session.value.lrnerId
      }).then(res => {
        if (lengthCheck(res)) {         
          newzTotInfo.value = getItems(res)[0];

        } else {
          newzTotInfo.value=false;
        }
      }).catch(e => {
        newzTotInfo.value=false;
        console.error(e);
      })
    }

    onMounted(() => {
      getOnboardingNoticeList();
      getNewZMemList();
    });

    const fnGoItemDtlScore = (pClssCd) => {

      //

      if(pClssCd ==null || pClssCd=="") return;
      console.log(session.value.lrnerId)
       router.push({name: 'NewzItemDtlScoreView', 
            params:{distCrseSn :  newzTotInfo.value?.distCrseSn , newzClssCd : pClssCd}});

    }


    

</script>